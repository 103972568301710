<template>
  <ValidationProvider
    ref="provider"
    v-slot="{ errors }"
    :rules="{ image: true, size: maxSize }"
    :name="name"
  >
    <div class="image-uploader-container">
      <div class="img-wrapper">
        <div class="inside">
          <img v-if="background" :src="background" class="input-wrapper" />
          <b-icon v-else class="default-img" icon="person-fill"></b-icon>
        </div>

        <div v-if="hasError" class="error-wrapper">
          <span class="error-msg">{{ errors[0] }}</span>
        </div>
      </div>
      <div class="file-input-container">
        <input
          class="d-none"
          ref="attachment"
          type="file"
          accept="image/*"
          @change="onFileChange"
        />
        <span class="btn-upload" @click="$refs.attachment.click()">
          <b-icon class="upload-icon" icon="camera"></b-icon>
        </span>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    maxSize: {
      type: Number,
      default: 1000 * 5, // in kilobytes
    },
    defaultImage: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    extentions: {
      type: Array,
      default: () => {
        return ["png", "jpg", "jpeg"];
      },
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      background: null,
      hasError: false,
    };
  },
  created() {
    this.background = this.defaultImage;
  },
  methods: {
    ...mapActions(["ShowToast"]),

    async onFileChange(changeEvent) {
      const extension = changeEvent.target.files[0].name.split(".").pop();
      if (!this.extentions.includes(extension)) {
        this.ShowToast({
          title: this.$t("GLOBAL_FAILED"),
          type: "danger",
          message: this.$t("GLOBAL_IMAGE_FORMAT"),
        });
        return false;
      }

      const uploadedImage = changeEvent.target.files[0] || "";
      const { valid } = await this.$refs.provider.validate(uploadedImage);
      if (!valid) {
        return (this.hasError = true);
      }

      if (!uploadedImage) return false;
      this.hasError = false;
      const fileReader = new FileReader();
      fileReader.onload = (loadEvent) => {
        this.background = loadEvent.target.result;
        this.$emit("image-upload", {
          background: this.background,
          uploadedImage,
        });
      };
      fileReader.readAsDataURL(uploadedImage);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index";
</style>
