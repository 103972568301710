<template>
  <div>
    <complete-profile-modal
      :loading="changeUserLoading"
      :selectedUser="selectedUser"
      modalTitle="MY_PROFILE.COMPLETE_PROFILE"
      confirmTitle="GLOBAL_COMPLETE"
      @handleChange="handleChangeUserName"
      @closeModal="handleCloseModal"
    />
    <link-exchange-modal :loading="loading" @handleLinkExchange="handleLinkExchange" />
    <confirm-complete-profile-modal @handleStartMoney="handleStartMoney" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { patchUserRequest } from "@/api/register";
import CompleteProfileModal from "@/components/Application/MyProfile/CompleteProfileModal";
import ConfirmCompleteProfileModal from "@/components/Application/MyProfile/ConfirmCompleteProfileModal";
import LinkExchangeModal from "@/components/Application/Exchange/LinkExchangeModal";
import { postLinkExchangeRequest } from "@/api/leaders";
export default {
  components: {
    CompleteProfileModal,
    ConfirmCompleteProfileModal,
    LinkExchangeModal,
  },

  data() {
    return {
      changeUserLoading: false,
      loading: false,
      selectedUser: null,
      modalName: null,
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.selectedUser = this.user;

        if (!this.user.first_name) {
          setTimeout(() => {
            this.$bvModal.show("complete-profile-modal");
          }, 100);
        }
      },
    },
  },

  computed: {
    ...mapGetters(["user", "token", "typeConfirmation"]),
  },

  methods: {
    ...mapActions(["SetUser", "ShowToast", "SetTypeConfirmation"]),

    // user name
    handleChangeUserName(formValue) {
      const body = {
        id: this.user.id,
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        name: `${formValue.firstName} ${formValue.lastName}`,
      };
      this.changeUserLoading = true;
      this.ApiService(patchUserRequest(body))
        .then(({ data }) => {
          this.$bvModal.hide("complete-profile-modal");
          this.$bvModal.show("link-exchange-modal");
          this.handleSetUser(data.meta.custom.message);
          this.SetTypeConfirmation("userConfirmation");
        })
        .finally(() => {
          this.changeUserLoading = false;
        });
    },
    handleLinkExchange(data) {
      const body = {
        name: data.name,
        exchange: data.exchange,
        exchange_data: {
          api_key: data.apiKey,
          api_secret: data.apiSecret,
        },
      };
      this.loading = true;
      this.ApiService(postLinkExchangeRequest(body))
        .then(({ data }) => {
          const params = {
            token: this.token,
          };
          this.handleSetUser(params);
          this.$bvModal.hide("link-exchange-modal");
          this.$bvModal.show("confirm-complete-profile-Modal");

          this.ShowToast({
            title: this.$t("GLOBAL_SUCCESS"),
            type: "success",
            message: data.meta.custom.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleSetUser(message) {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
      this.handleSetMessage(message);
    },
    handleSetMessage(message) {
      this.ShowToast({
        title: this.$t("GLOBAL_SUCCESS"),
        type: "success",
        message: message,
      });
    },
    handleCloseModal() {
      this.modalName = null;
      this.selectedUser = null;
    },

    handleStartMoney() {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
    },
  },
};
</script>

<style lang="scss" scoped></style>
