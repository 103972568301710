<template>
  <div>
    <b-modal
      id="confirm-complete-profile-Modal"
      hide-footer
      size="lg"
      :hide-header="typeConfirmation !== 'linkConfirmation'"
      centered
    >
      <template v-slot:modal-header="{ close }" v-if="typeConfirmation === 'linkConfirmation'">
        <h6 class="mb-0"></h6>
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'" class="body-container" v-if="user">
        <div v-if="typeConfirmation === 'linkConfirmation'">
          <div class="defualt-image">
            <img src="@/assets/images/link-success.svg" class="img-fluid" alt="" />
          </div>
          <h2 class="link-title">
            {{ $t("LEADERS.LINKING_SUCCESS") }}
          </h2>
          <p>
            {{ $t("LEADERS.LINK_EXCHANGE_LINKED") }}
          </p>
          <div>
            <Button customClass="text-uppercase continue-btn" @click="handleStartMoney">
              {{ $t("GLOBAL_CONTINUE") }}
            </Button>
          </div>
        </div>
        <div v-else>
          <h2 class="title">
            {{ $t("MY_PROFILE.THANK_YOU") }}
            <span>
              {{ user.name }}
            </span>
          </h2>
          <p class="description">
            {{ $t("MY_PROFILE.COMPLETE_PROFILE") }}
          </p>

          <div class="user-profile" v-if="user.image">
            <div
              class="image"
              :style="{
                backgroundImage: `url(${user.image})`,
              }"
            ></div>
          </div>
          <div v-else class="defualt-image">
            <img src="@/assets/images/complete-profile.png" class="img-fluid" alt="" />
          </div>
          <div>
            <Button customClass="text-uppercase start-money-btn" @click="handleStartMoney">
              {{ $t("MY_PROFILE.START_MONEY") }}
            </Button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["user", "typeConfirmation"]),
  },
  methods: {
    handleStartMoney() {
      this.$bvModal.hide("confirm-complete-profile-Modal");
      this.$emit("handleStartMoney");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
