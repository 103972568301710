import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import RegisterationLayout from "@/layouts/RegisterationLayout";
import TextField from "@/components/Shared/TextField";
import TextAreaField from "@/components/Shared/TextAreaField";
import SelectField from "@/components/Shared/SelectField";
import SelectSearch from "@/components/Shared/SelectSearch";
import CheckboxField from "@/components/Shared/CheckboxField";
import RadioButton from "@/components/Shared/RadioButton";
import Button from "@/components/Shared/Button";
import ConfirmationModal from "@/components/Shared/ConfirmationModal";
import ToastConfirmationModal from "@/components/Shared/ToastConfirmationModal";
import Empty from "@/components/Shared/Empty";

const components = {
  ValidationProvider,
  ValidationObserver,
  RegisterationLayout,
  TextField,
  TextAreaField,
  SelectField,
  SelectSearch,
  CheckboxField,
  RadioButton,
  Button,
  ConfirmationModal,
  ToastConfirmationModal,
  Empty,
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
