import HomeLayout from "@/layouts/HomeLayout";
const protectedRoutes = [
  {
    path: "/",
    component: HomeLayout,
    children: [
      {
        path: "",
        redirect: "home",
      },
      {
        path: "home",
        name: "home",
        component: () => import("../views/application/home"),
      },
      {
        path: "explorer",
        name: "explorer",
        component: () => import("../views/application/explorer"),
      },
      {
        path: "explorer/:id",
        name: "explorer-info",
        component: () => import("../views/application/explorer/info"),
      },
      {
        path: "leaders",
        name: "leaders",
        component: () => import("../views/application/leaders"),
      },
      {
        path: "leaders/:id",
        name: "leader-profile",
        component: () => import("../views/application/leaders/leader-profile"),
      },
      {
        path: "my-profile",
        name: "my-profile",
        component: () => import("../views/application/my-profile"),
        children: [
          {
            path: "",
            redirect: "account-management",
          },
          {
            path: "account-management",
            name: "account-management",
            component: () => import("../views/application/my-profile/account-management"),
          },
          {
            path: "address-book",
            name: "address-book",
            component: () => import("../views/application/my-profile/address-book"),
          },
          {
            path: "payment",
            name: "payment",
            component: () => import("../views/application/my-profile/payment"),
          },
          {
            path: "subscription-plan",
            name: "subscription-plan",
            component: () => import("../views/application/my-profile/subscription-plan"),
          },
          {
            path: "notifications",
            name: "notifications",
            component: () => import("../views/application/my-profile/notifications"),
          },
          {
            path: "exchanges",
            name: "exchanges",
            component: () => import("../views/application/my-profile/exchanges"),
          },
          {
            path: "followers",
            name: "followers",
            component: () => import("../views/application/my-profile/followers"),
          },
        ],
      },
    ],
  },
  //  {
  //   path: "/about",
  //   name: "about",
  //   component: () => import("../views/about"),
  // },
];

export default protectedRoutes;
