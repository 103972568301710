import { publicRoutesPaths } from "./publicRoutes";
import store from "@/store";

export const handleRouteNavigation = (to, from, next) => {
  if (publicRoutesPaths.includes(to.path)) {
    return !store.getters.token ? next() : next({ name: "index" });
  }

  if (store.getters.token) return next();

  store.dispatch("RemoveUser");
  next({ name: "login" });
};
