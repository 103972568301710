<template>
  <div>
    <home-top-nav @showSideMenu="showSideMenu = true" />

    <div>
      <div class="side-menu" :class="{ active: showSideMenu }">
        <side-menu @hideSideMenu="showSideMenu = false" />
        <!-- 
        <router-link
          class="router"
          to="/my-profile"
          @click.native="showSideMenu = false"
        >
        <img class="icon-img" :src="route.icon" /> 

          {{ $t("GLOBAL_MY_PROFILE") }}
        </router-link>-->
      </div>
      <main class="home-layout" :class="{ 'main-active': showSideMenu }">
        <div class="container-fluid">
          <router-view></router-view>
          <footer-layout class="text-center mt-5" />
        </div>
      </main>
    </div>
    <!-- complete your profile -->
    <complete-profile-mixins id="change-username-modal" />
  </div>
</template>

<script>
import HomeTopNav from "@/components/Layouts/HomeTopNav";
import SideMenu from "@/components/Layouts/SideMenu";
import FooterLayout from "@/components/Layouts/Footer";
import CompleteProfileMixins from "@/mixins/CompleteProfileMixins";

export default {
  components: {
    HomeTopNav,
    SideMenu,
    FooterLayout,
    CompleteProfileMixins,
  },
  data() {
    return {
      showSideMenu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
