import Vue from "vue";
import Vuex from "vuex";
import toast from "./modules/toast";
import user from "./modules/user";
import notifications from "./modules/notifications";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    toast,
    notifications,
  },
});
