<template>
  <div>
    <b-navbar toggleable="lg" class="navbar-container">
      <div class="d-flex">
        <router-link to="/">
          <img class="top-logo" src="@/assets/images/logo.svg" alt="" />
          <img class="mobile-logo" src="@/assets/images/logo-mobile.svg" alt="" />
        </router-link>
        <button class="btn show-side-menu" @click="$emit('showSideMenu')">
          <b-icon class="icon-check" icon="list"></b-icon>
        </button>
      </div>

      <b-navbar-nav class="ml-auto">
        <b-dropdown
          variant="transparent"
          :right="$i18n.locale === 'en'"
          class="list-nav list-image"
          :class="{ verified: user.email_verified_at }"
          v-if="user"
        >
          <template #button-content>
            <image-circle :defaultImage="user.image" />
          </template>
          <b-dropdown-item to="/my-profile">
            {{ $t("GLOBAL_MY_PROFILE") }}
          </b-dropdown-item>
          <b-dropdown-item :loading="loading" @click="Logout(token)">
            {{ $t("GLOBAL_LOGOUT") }}
          </b-dropdown-item>
        </b-dropdown>

        <b-dropdown
          variant="transparent"
          :right="$i18n.locale === 'en'"
          class="list-nav language-container"
        >
          <template v-slot:button-content>
            <span>
              {{ $i18n.locale === "ar" ? $t("GLOBAL_AR") : $t("GLOBAL_EN") }}
            </span>
          </template>

          <b-dropdown-item
            v-for="(language, index) in languages"
            :key="index"
            @click="changeLang(language.value)"
          >
            <b-icon
              class="icon-language"
              :icon="$i18n.locale === language.value ? 'check' : ''"
            ></b-icon>
            <span>
              {{ language.name }}
            </span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- <button class="btn btn-text p-0 list-nav">
          <b-icon class="icon-check" icon="moon"></b-icon>
        </button> -->

        <b-dropdown
          variant="transparent"
          :right="$i18n.locale === 'en'"
          class="notification-container list-nav"
        >
          <template #button-content>
            <span class="notification-count">
              {{ unreadNotificationsCount }}
            </span>
            <b-icon class="icon-check" icon="bell-fill"></b-icon>
          </template>

          <template v-if="notifications.length">
            <b-dropdown-item
              v-for="(notification, index) in notifications"
              :key="index"
              class="notification-list"
              :class="{ unreadable: !notification.readable_read_at }"
              @click="handleReadNotification(notification)"
            >
              <p>
                {{
                  $i18n.locale == "en" ? notification.data.en_content : notification.data.ar_content
                }}
              </p>
              <span class="date text-right">
                {{ notification.readable_created_at }}
              </span>
            </b-dropdown-item>

            <!-- pagination -->
            <infinite-loading
              v-if="notifications.length"
              :identifier="infiniteId"
              @infinite="handleLoadNotifications"
            >
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </template>
          <div v-else-if="!notifications.length && !loadingNotifications" class="text-center p-3">
            {{ $t("MY_PROFILE.NO_NOTIFICATIONS") }}
          </div>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ImageCircle from "@/components/Shared/ImageCircle";
import client from "@/ably";

import {
  getLoadNotificationsRequest,
  getLoadNotificationStatsRequest,
  patchReadNotificationRequest,
} from "@/api/register";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    ImageCircle,
    InfiniteLoading,
  },
  computed: {
    ...mapGetters(["loading", "token", "user"]),

    currentRoute() {
      return this.$route.name;
    },
  },

  created() {
    this.handleLoadNotifications();
    this.handleLoadNotificationCount();
  },
  mounted() {
    this.handleSubscribeUserChannel();
  },
  data() {
    return {
      languages: [
        { value: "ar", name: this.$t("GLOBAL_AR") },
        { value: "en", name: this.$t("GLOBAL_EN") },
      ],
      notifications: [],
      infiniteId: +new Date(),
      page: 1,
      perPage: 15,
      unreadNotificationsCount: 0,
      loadingNotifications: false,
      notificationsChannelIdSubscription: null,
      channel: null,
    };
  },

  methods: {
    ...mapActions(["Logout", "ShowToast", "SetNotification", "SetSocketData"]),

    changeLang(value) {
      localStorage.setItem("lang", value);
      location.reload();
    },
    handleRedirect() {
      if (this.currentRoute === "login") {
        this.$router.push("/register");
      } else {
        this.$router.push("/login");
      }
    },

    handleLoadNotifications($state) {
      const params = {
        limit: 50,
        page: this.page,
      };
      this.loadingNotifications = true;
      this.ApiService(getLoadNotificationsRequest(params))
        .then(({ data }) => {
          if (data.data.length) {
            this.page += 1;
            this.notifications.push(...data.data);
            $state?.loaded();
          } else {
            $state?.complete();
          }
        })
        .finally(() => {
          this.loadingNotifications = false;
        });
    },

    handleLoadNotificationCount() {
      this.ApiService(getLoadNotificationStatsRequest()).then(({ data }) => {
        this.unreadNotificationsCount = data.data.unread_notifications;
      });
    },
    handleReadNotification(notification) {
      this.ApiService(patchReadNotificationRequest(notification.id)).then(() => {
        this.page = 1;
        this.notifications = [];
        this.handleLoadNotifications();
        this.handleLoadNotificationCount();
        this.$router.push(this.RemoveBaseUrl(notification.data.link));
      });
    },
    RemoveBaseUrl(url) {
      /* eslint-disable-next-line */
      let baseUrlPattern = /^https?:\/\/[a-z\:0-9.]+/;
      let result = "";

      let match = baseUrlPattern.exec(url);
      if (match != null) {
        result = match[0];
      }

      if (result.length > 0) {
        url = url.replace(result, "");
      }

      return url;
    },
    async handleSubscribeUserChannel() {
      await client.connection.on("connected", () => {
        console.log("Server connected");
      });

      if (this.user) {
        this.channel = client.channels.get(`private:user.${this.user.id}`);
        this.channel.subscribe(({ data }) => {
          if (this.token) {
            console.log("socket :>> ", data);
            this.SetSocketData(data);
            this.SetNotification(data);
            this.ShowToast({
              title: this.$t("MY_PROFILE.NEW_NOTIFICATION"),
              type: "success",
              message: data.message,
            });

            this.notifications = [];
            this.page = 1;
            this.handleLoadNotifications();
            this.handleLoadNotificationCount();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
