<template>
  <div id="nav" :class="$i18n.locale == 'en' ? '' : 'rtl'">
    <toast />
    <router-view />
  </div>
</template>

<script>
import Toast from "@/components/Shared/Toast";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Toast,
  },
  created() {
    const html = document.documentElement;
    html.setAttribute("lang", this.$i18n.locale == "ar" ? "ar" : "en");
    html.setAttribute("dir", this.$i18n.locale == "ar" ? "rtl" : "ltr");
    if (this.token) {
      this.handleGetUser();
    }
  },
  computed: {
    ...mapGetters(["token"]),
  },

  methods: {
    ...mapActions(["SetUser"]),

    handleGetUser() {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
    },
  },
};
</script>

<style lang="scss">
* {
  &:lang(ar) {
    font-family: "Tajawal", sans-serif;
  }
  &:lang(en) {
    font-family: "Archivo", sans-serif;
  }
}
body {
  &:lang(ar) {
    text-align: right;
  }
  &:lang(en) {
    text-align: left;
  }
}
</style>
