<template>
  <div>
    <b-navbar toggleable="lg" class="navbar-container">
      <b-navbar-nav class="ml-auto d-flex" :right="$i18n.locale === 'en'">
        <b-dropdown variant="transparent" :right="$i18n.locale === 'en'" class="list-nav">
          <template v-slot:button-content>
            <span>
              {{ $i18n.locale === "ar" ? $t("GLOBAL_AR") : $t("GLOBAL_EN") }}
            </span>
          </template>

          <b-dropdown-item
            v-for="(language, index) in languages"
            :key="index"
            @click="changeLang(language.value)"
          >
            <b-icon
              class="icon-language"
              :icon="$i18n.locale === language.value ? 'check' : ''"
            ></b-icon>
            <span>
              {{ language.name }}
            </span>
          </b-dropdown-item>
        </b-dropdown>

        <!-- <Button color="primary" customClass="btn-sm" @click="handleRedirect">
          {{ currentRoute === "login" ? $t("LOGIN_REGISTER") : $t("LOGIN_LOGIN") }}
        </Button> -->
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  data() {
    return {
      languages: [
        { value: "ar", name: this.$t("GLOBAL_AR") },
        { value: "en", name: this.$t("GLOBAL_EN") },
      ],
    };
  },
  methods: {
    changeLang(value) {
      localStorage.setItem("lang", value);
      location.reload();
    },
    handleRedirect() {
      if (this.currentRoute === "login") {
        this.$router.push("/register");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
