<template>
  <div>
    <button class="btn button-menu" @click="$emit('hideSideMenu')">
      <b-icon class="icon-check" icon="x"></b-icon>
    </button>
    <div class="menu-container">
      <router-link
        v-for="(route, index) in routes"
        :key="index"
        class="router"
        :to="`/${route.to}`"
        :name="route.to"
        @click.native="$emit('hideSideMenu')"
      >
        <img class="icon-img" :src="route.icon" />

        {{ route.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        {
          name: this.$t("SIDE_MENU.DASHBOARD"),
          to: "home",
          icon: require("@/assets/images/home-icon.svg"),
        },
        {
          name: this.$t("SIDE_MENU.LEADERS"),
          to: "leaders",
          icon: require("@/assets/images/pro-icon.svg"),
        },
        {
          name: this.$t("SIDE_MENU.EXPLORER"),
          to: "explorer",
          icon: require("@/assets/images/explorer-icon.svg"),
        },
        {
          name: this.$t("SIDE_MENU.MY_PROFILE"),
          to: "my-profile",
          icon: require("@/assets/images/profile-icon.svg"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
