<template>
  <div class="registeration-layout">
    <top-nav />
    <slot />
    <footer-layout class="text-center" />
  </div>
</template>

<script>
import TopNav from "@/components/Layouts/TopNav";
import FooterLayout from "@/components/Layouts/Footer";
export default {
  components: {
    TopNav,
    FooterLayout,
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
