export const postLoginRequest = (data) => ({
  method: "post",
  url: "clients/web/login",
  config: {
    data,
  },
});

export const postRegisterRequest = (data) => ({
  method: "post",
  url: "register",
  config: {
    data,
  },
});

export const postForgotPasswordRequest = (data) => ({
  method: "post",
  url: "password/forgot",
  config: {
    data,
  },
});

export const putUpdateUserRequest = (data) => ({
  method: "post",
  url: `users/${data.id}`,
  config: {
    data,
  },
});

export const postResetPasswordRequest = (data) => ({
  method: "post",
  url: "password/reset",
  config: {
    data,
  },
});

export const deleteLogoutRequest = (data) => ({
  method: "delete",
  url: "logout",
  config: {
    data,
  },
});

export const getUserProfileRequest = (params) => ({
  method: "get",
  url: `profile`,
  config: {
    params,
  },
});

export const patchUserRequest = (data) => ({
  method: "patch",
  url: `users/${data.id}`,
  config: {
    data,
  },
});

export const patchChangePasswordRequest = (data) => ({
  method: "patch",
  url: `users/${data.id}/password`,
  config: {
    data,
  },
});

export const getLoadNotificationsRequest = (params) => ({
  method: "get",
  url: "notifications",
  config: {
    params,
  },
});

export const getLoadNotificationStatsRequest = () => ({
  method: "get",
  url: "notifications-stats",
});

export const patchReadNotificationRequest = (id) => ({
  method: "patch",
  url: `notifications/${id}`,
});

export const getLoadTermsConditionsRequest = () => ({
  method: "get",
  url: "terms",
});

export const postUploadImageRequest = (data, id) => ({
  method: "post",
  url: `users/${id}?_method=patch`,
  config: {
    data,
  },
});

export const deleteAccountRequest = (id) => ({
  method: "delete",
  url: `users/${id}`,
});
