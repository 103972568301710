<template>
  <b-modal id="link-exchange-modal" hide-footer @hide="closeModal" size="lg" centered>
    <template v-slot:modal-header="{ close }">
      <h6 class="mb-0"></h6>
      <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
    </template>
    <div :class="$i18n.locale == 'en' ? '' : 'rtl'" class="body-container">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formLinkExchange">
          <div class="row complete-profile-container">
            <div class="col-lg-6">
              <h2 class="title">
                {{ $t("MY_PROFILE.EXCHANGE_CONNECT") }}
              </h2>
              <div class="exchange-form">
                <div class="mb-2">
                  <TextField
                    v-model="form.apiKey"
                    rules="required"
                    name="API KEY"
                    label="API KEY"
                  />
                </div>
                <div class="mb-3">
                  <TextField
                    v-model="form.apiSecret"
                    rules="required"
                    name="API SECRET"
                    label="API SECRET"
                  />
                </div>
                <b-form-group class="text-right">
                  <Button
                    type="button"
                    color="light"
                    customClass="text-uppercase font-14 mr-3"
                    @click="$bvModal.hide('link-exchange-modal')"
                  >
                    {{ $t("MY_PROFILE.MAYBE_LATER") }}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    :loading="loading"
                    customClass="text-uppercase font-14"
                  >
                    {{ $t("MY_PROFILE.CONNECT") }}
                  </Button>
                </b-form-group>
              </div>
            </div>
            <div class="col-lg-6">
              <p class="description">
                {{ $t("MY_PROFILE.EXCHANGE_STEPS") }}
              </p>
              <video width="100%" height="195" controls>
                <source src="movie.mp4" type="video/mp4" />
                <source src="movie.ogg" type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "Key" + Math.random().toString().slice(0, 4),
        exchange: "binance",
        apiKey: null,
        apiSecret: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        name: "Key" + Math.random().toString().slice(0, 4),
        exchange: "binance",
        apiKey: null,
        apiSecret: null,
      };
    },
    onSubmit() {
      this.$emit("handleLinkExchange", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
