<template>
  <div>
    <b-modal :id="id" hide-footer size="lg" @hide="closeModal" :hide-header="welcomeStep" centered>
      <template v-slot:modal-header="{ close }" v-if="!welcomeStep">
        <h6 class="mb-0"></h6>
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>

      <div :class="$i18n.locale == 'en' ? '' : 'rtl'" class="body-container">
        <div class="welcome-container row" v-if="welcomeStep">
          <div class="col-lg-6 left-side">
            <h2 class="title">
              {{ $t("LOGIN_WELCOME_TO") }}
              <span>
                {{ $t("REMORA") }}
              </span>
            </h2>
            <p class="description">
              Lorem ipsum It is a long established fact that a reader will be distracted by the
              readable content of a page when looking at its layout.
            </p>
            <b-form-group>
              <Button @click="handleNextAction">
                {{ $t("GLOBAL_NEXT") }}
              </Button>
            </b-form-group>
          </div>
          <div class="col-lg-6 right-side">
            <img
              v-if="$i18n.locale == 'en'"
              src="@/assets/images/welcome.png"
              alt=""
              class="img-fluid"
            />
            <img v-else src="@/assets/images/welcome-ar.png" alt="" class="img-fluid" />
          </div>
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" v-else>
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddUsername">
            <div class="row complete-profile-container">
              <div class="col-lg-6">
                <h2 class="title">
                  {{ $t("MY_PROFILE.COMPLETE_YOUR_PROFILE") }}
                  <span>
                    {{ $t("MY_PROFILE.PROFILE") }}
                  </span>
                </h2>

                <div class="d-flex">
                  <div class="profile-image">
                    <h5 class="profile-title">
                      {{ $t("MY_PROFILE.PICTURE") }}
                    </h5>
                    <image-uploader
                      :defaultImage="selectedUser.image"
                      :maxSize="2048"
                      :name="$t('MY_PROFILE.IMAGE')"
                      @image-upload="handleImageUpload"
                    />
                  </div>
                  <div>
                    <div class="mb-2">
                      <TextField
                        v-model="form.firstName"
                        rules="required"
                        :label="$t('LOGIN_FIRST_NAME')"
                        :name="$t('LOGIN_FIRST_NAME')"
                      />
                    </div>
                    <div>
                      <TextField
                        v-model="form.lastName"
                        rules="required"
                        :label="$t('LOGIN_LAST_NAME')"
                        :name="$t('LOGIN_LAST_NAME')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <img src="@/assets/images/profile.png" alt="" class="img-fluid" />
              </div>
            </div>
            <b-form-group class="d-flex justify-content-end">
              <Button type="submit" color="primary" :loading="loading">
                {{ $t("GLOBAL_NEXT") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ImageUploader from "@/components/Shared/ImageUploader";
import { postUploadImageRequest } from "@/api/register";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    selectedUser: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "complete-profile-modal",
    },
    modalTitle: {
      type: String,
      default: "MY_PROFILE.CHNAGE_USER_NAME",
    },
    confirmTitle: {
      type: String,
      default: "GLOBAL_NEXT",
    },
  },
  components: {
    ImageUploader,
  },
  computed: {
    ...mapGetters(["token"]),
  },

  watch: {
    selectedUser: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.form = {
          firstName: val.first_name,
          lastName: val.last_name,
        };
      },
    },
  },

  data() {
    return {
      welcomeStep: localStorage.getItem("welcomeStep") === "true" ? false : true,
      form: {
        firstName: null,
        lastName: null,
      },
    };
  },
  methods: {
    ...mapActions(["SetUser", "ShowToast"]),

    closeModal() {
      setTimeout(() => {
        localStorage.setItem("welcomeStep", "true");
        this.welcomeStep = false;
      }, 400);
      if (this.welcomeStep) {
        this.$refs.observer.reset();
        this.form = {
          firstName: null,
          lastName: null,
        };
      }
    },
    handleNextAction() {
      this.welcomeStep = false;
      localStorage.setItem("welcomeStep", true);
    },
    handleImageUpload(file) {
      let formData = new FormData();
      formData.append("image", file.uploadedImage);
      // this.passwordLoading = true;
      this.ApiService(postUploadImageRequest(formData, this.selectedUser.id)).then(({ data }) => {
        this.handleSetUser(data.meta.custom.message);
      });
    },

    handleSetUser(message) {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
      this.handleSetMessage(message);
    },
    handleSetMessage(message) {
      this.ShowToast({
        title: this.$t("GLOBAL_SUCCESS"),
        type: "success",
        message: message,
      });
    },

    onSubmit() {
      this.$emit("handleChange", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
