const publicRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/registeration/login"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/registeration/register"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/registeration/forgot-password"),
  },
  {
    path: "/choose-password",
    name: "choose-password",
    component: () => import("../views/registeration/choose-new-password"),
  },
];

export const publicRoutesPaths = publicRoutes.map((route) => route.path);

export default publicRoutes;
