var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{class:{
    'd-block': _vm.dBlock,
    'd-inline-block': !_vm.dBlock,
    'not-allowed': _vm.loading,
  },attrs:{"show":_vm.loading,"rounded":"","opacity":"0","spinner-small":"","spinner-variant":"primary"}},[_c('b-button',_vm._g(_vm._b({staticClass:"base-btn",class:( _obj = {
      'not-allowed': _vm.loading
    }, _obj[_vm.customClass] = _vm.customClass, _obj ),attrs:{"variant":_vm.color,"disabled":_vm.disabled}},'b-button',_vm.$attrs,false),_vm.$listeners),[_vm._t("default"),(_vm.loading)?_c('b-spinner',{staticClass:"mx-2",attrs:{"small":""}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }