var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"img-wrapper",style:({
      height: _vm.height + 'px',
      width: _vm.width + 'px',
    })},[_c('div',{staticClass:"inside",style:({
        height: _vm.height - 0.1 + 'px',
        width: _vm.width - 0.1 + 'px',
      })},[(_vm.background)?_c('img',{staticClass:"input-wrapper",attrs:{"src":_vm.background}}):_c('b-icon',{staticClass:"default-img",style:({
          height: _vm.iconHeight + 'px',
          width: _vm.iconWidth + 'px',
        }),attrs:{"icon":"person-fill"}})],1)]),(_vm.showCoin)?_c('div',{staticClass:"file-input-container"},[(_vm.coinImage)?_c('img',{staticClass:"input-wrapper",attrs:{"src":_vm.coinImage}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }