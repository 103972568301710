import router from "@/router";
import ApiService from "@/api/ApiService";
import {
  postRegisterRequest,
  postLoginRequest,
  getUserProfileRequest,
  deleteLogoutRequest,
  postResetPasswordRequest,
} from "@/api/register";
import VueCookies from "vue-cookies";

export default {
  state: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    loading: false,
    token: VueCookies.get("token") || null,
    openModalName: null,
    typeConfirmation: null,
  },
  getters: {
    user: (state) => state.user,
    loading: (state) => state.loading,
    token: (state) => state.token,
    typeConfirmation: (state) => state.typeConfirmation,
  },
  actions: {
    Register({ commit, dispatch }, payload) {
      const body = {
        email: payload.email,
        password: payload.password,
        first_name: payload.firstName,
        last_name: payload.lastName,
        name: `${payload.firstName} ${payload.lastName}`,
        referral_code: payload.referralCode,
        verification_url: process.env.VUE_APP_ROOT + "email/verify",
      };
      commit("SET_LOADING", true);

      ApiService(postRegisterRequest(body))
        .then(async () => {
          await dispatch("Login", payload);
          localStorage.setItem("welcomeStep", false);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },

    Login({ commit, dispatch }, payload) {
      const body = {
        email: payload.email,
        password: payload.password,
      };
      this.showLoading = true;
      commit("SET_LOADING", true);

      ApiService(postLoginRequest(body))
        .then(async (res) => {
          const token = res.data.access_token;
          VueCookies.set("token", token);
          commit("SET_TOKEN", token);
          await dispatch("SetUser", {
            token: token,
            route: true,
          });
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },

    SetUser({ commit }, { token, route = false }) {
      const params = {
        authorization: `Bearer ${token}`,
        include: "exchanges,followedLeaders,wallets,leader,history",
      };
      commit("SET_LOADING", true);
      ApiService(getUserProfileRequest(params))
        .then(async (res) => {
          localStorage.setItem("user", JSON.stringify(res.data.data));

          commit("SET_USER", res.data.data);
          if (route) {
            router.push("/");
          }
          /*
            const response = res.data.data
            localStorage.setItem("user", JSON.stringify(response));
  
            commit("SET_USER", res.data.data);
            if (!response.first_name) {
              router.push("/my-profile");
            }
            else if (route) {
              router.push("/");
            }
          */
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },

    ResetPassword({ commit, dispatch }, payload) {
      const body = {
        email: payload.email,
        token: payload.token,
        password: payload.password,
      };

      commit("SET_LOADING", true);
      ApiService(postResetPasswordRequest(body))
        .then(async () => {
          await dispatch("Login", payload);
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },

    Logout({ commit, dispatch }, token) {
      const params = {
        authorization: `Bearer ${token}`,
      };

      commit("SET_LOADING", true);
      ApiService(deleteLogoutRequest(params))
        .then(async () => {
          await dispatch("RemoveUser");
        })
        .finally(() => {
          commit("SET_LOADING", false);
        });
    },

    RemoveUser({ commit }) {
      VueCookies.remove("token");
      localStorage.removeItem("user");
      localStorage.removeItem("ably-transport-preference");
      commit("REMOVE_USER");
      router.push("/login");
    },
    // UpdateUser({ commit }, payload) {
    //   commit("SET_USER", payload);
    //   VueCookies.set("user", JSON.stringify(payload));
    // },
    SetModalName({ commit }, payload) {
      commit("SET_MODAL_NAME", payload);
    },

    SetTypeConfirmation({ commit }, payload) {
      commit("SET_TYPE_CONFIRMATION", payload);
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    REMOVE_USER(state) {
      state.user = null;
      state.token = null;
    },
    SET_LOADING: (state, value) => {
      state.loading = value;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_MODAL_NAME(state, modal) {
      state.openModalName = modal;
    },
    SET_TYPE_CONFIRMATION(state, modal) {
      state.typeConfirmation = modal;
    },
  },
};
