export const getLeadersRequest = (params) => ({
  method: "get",
  url: `leaders`,
  config: {
    params,
  },
});

export const postFollowRequest = (data) => ({
  method: "post",
  url: `leaders/follow/${data.id}`,
  config: {
    data,
  },
});

export const postUnfollowRequest = (data) => ({
  method: "post",
  url: `leaders/unfollow/${data.id}`,
  config: {
    data,
  },
});

export const getLeaderRequest = (params) => ({
  method: "get",
  url: `leaders/${params.id}`,
  config: {
    params,
  },
});

export const postAddPostRequest = (data) => ({
  method: "post",
  url: `leaders/${data.id}/post`,
  config: {
    data,
  },
});

export const postAddCommentRequest = (data) => ({
  method: "post",
  url: "users/create-comment",
  config: {
    data,
  },
});

export const postAddLeaderRequest = (data) => ({
  method: "post",
  url: "leaders",
  config: {
    data,
  },
});

export const getLoadSettingsByTypeRequest = (params) => ({
  method: "get",
  url: "settings",
  config: {
    params,
  },
});

export const postReportLeaderRequest = (data) => ({
  method: "post",
  url: `leaders/report/${data.id}`,
  config: {
    data,
  },
});

export const deleteExchangeRequest = (data) => ({
  method: "delete",
  url: `exchange/connect/${data.id}`,
});

export const postLinkExchangeRequest = (data) => ({
  method: "post",
  url: "exchange/connect",
  config: {
    data,
  },
});

export const postCommentReactionRequest = (data) => ({
  method: "post",
  url: `users/create-comment-reaction/${data.id}`,
  config: {
    data,
  },
});

export const getOpenOrdersRequest = (params) => ({
  method: "get",
  url: "opened-orders",
  config: {
    params,
  },
});

export const getLatestRepliesRequest = (params) => ({
  method: "get",
  url: "latest-replies",
  config: {
    params,
  },
});

export const deleteCancelOrderRequest = (params) => ({
  method: "delete",
  url: "open-order",
  config: {
    params,
  },
});

export const deleteCancelAllOrdersRequest = (params) => ({
  method: "delete",
  url: "open-orders",
  config: {
    params,
  },
});

export const patchLeaderRequest = (data) => ({
  method: "patch",
  url: `leaders/${data.id}`,
  config: {
    data,
  },
});

export const getExchangeInfoRequest = () => ({
  method: "get",
  url: "exchange-info",
});

export const postBuyOrderRequest = (data) => ({
  method: "post",
  url: "buy-order",
  config: {
    data,
  },
});

export const postSellOrderRequest = (data) => ({
  method: "post",
  url: "sell-order",
  config: {
    data,
  },
});

export const getLeaderActivitiesRequest = () => ({
  method: "get",
  url: "leader/activities",
});

export const getMyTradsRequest = () => ({
  method: "get",
  url: "my-trades",
});
