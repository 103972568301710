<template>
  <footer>
    <p class="footer-text">
      {{ $t("GLOBAL_COPYRIGHT") }}
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
